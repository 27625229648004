@import "vars";

body {
    font-family: var(--font-body);
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: 0.32px;
    color: var(--navy);
    font-weight: normal;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
hr {
	border-color: $grey;
}
h2, h3, h4 {
	font-family: var(--font-heading);
}
h1,
h2,
h3 {
	line-height: 1.2;
}
h2 {
	font-size: 2rem;
	letter-spacing: 0.6px;
}
h3 {
	font-size: 2rem;
	letter-spacing: 0.6px;
}
h4 {
	font-size: 1.4rem;
	line-height: 1.4;
	letter-spacing: 0.44px;
}


.clear-float {
    clear:both;
}

blockquote {
	font-family: var(--font-heading);
	font-size: 1.5rem;
	letter-spacing: 0.48px;
	line-height: 1.4;
	font-weight: bold;
	padding: 0px;
	margin-left: 0px;
}

/* heading styles */
h2,
h3 {
	&[title-font="true"] {
		font-family: var(--font-title);
	}
	&.squiggle {
		position: relative;
		display: inline-block;
		padding-bottom: 10px; /* for the line */
		overflow: hidden;
		&::before,
		&::after {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 0px;
			display: inline-block;
		}
		&::before {
			width: 300px;
			height: 10px;
			background-image: url('../assets/squiggle.svg');
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 100% 10px;
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 300px;
			display: inline-block;
			width: calc(100% - 300px);
			height: 10px;
			background-image: url('../assets/squiggle-dot.svg');
			background-repeat: repeat-x; /* no vertical */
		}
		&[squiggle="orange"]{
			color: colour("orange");
			&::before {
				background-image: url('../assets/orange-squiggle.svg');
			}
			&::after {
				background-image: url('../assets/orange-squiggle-dot.svg');
			}
		}
	}
}

/* colour text spans */
.orange-text {
	color: colour("orange");
}
.grey-text {
	color: get_opacity("navy", 0.4);
}

/* buttons */
.button_solid {
	// orange default
    @include button_solid;
}
.button_hollow {
	// navy default
	@include button_hollow;
}

img {
	&.display-left {
	    float:left;
	    max-width: 50%;
	    margin-right:30px;
	    margin-bottom:30px;
	}
	&.display-right {
	    float:right;
	    max-width: 50%;
	    margin-left:30px;
	    margin-bottom:30px;
	}
}

@media only screen and (max-width: 760px){
    img {
        &.display-left,
        &.display-right {
            float: none;
	        max-width: 100%;
	        margin: 20px 0;
        }
    }
}

@media only screen and (max-width: 300px){
	.button_solid,
	.button_hollow,
	form button {
		// prevent overflow
		padding: 10px;
		min-width: 0px;
		width: 100%;
	}
}